import NotFound from '@skyscanner-internal/global-components/cjs/not-found';
import logger from 'saddlebag-logger';

export default (props) => {
  const newProps = { ...props };
  if (typeof window !== 'undefined') {
    newProps.logger = logger;
  }
  return <NotFound {...newProps} />;
};
